<template>
  <div :class="mobileMode ? 'mobilePanel' : 'panel'">
    <div class="button" @click="$emit('change', 'plus')"><font-awesome-icon icon="plus"/></div>
    <div class="button" @click="$emit('change', 'minus')"><font-awesome-icon icon="minus"/></div>
    <div class="zoomValueContainer"><div class="zoomValue noselect"> {{ this.zoomValue }}</div></div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
library.add(faPlus, faMinus)
export default {
  name: 'Zoom',
  components: {
    FontAwesomeIcon
  },
  props: {
    mobileMode: {
      type: Boolean,
      default: false
    },
    zoomValue: {
      type: Number
    }
  }
}
</script>

<style scoped>
.zoomValueContainer {
  position: relative;
  top: -58px;
  width: 45px;
  right: 0px;
}
.zoomValue {
  background-color: #e0e0e0;
  padding: 4px;
  border-radius: 6px;
  width: fit-content;
  margin:auto;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.mobilePanel {
  display: none;
}
.panel {
  background: white;
  width: 44px;
  height: 88px;
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06);
}
.button {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: white;
  cursor: pointer;
}
.button-blocked {

}
.button svg {
  color: #6E7B87;
  font-size: 16px;
  padding: 14px;
}
.button:hover {
  background-color: #ecf5ff;
}
.button:hover svg {
  color: #409EFF;
}
.button:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.button:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>
